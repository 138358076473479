import React, { useEffect, useState } from 'react';
import MapComponent from './MapComponent';
import SearchComponent from './SearchComponent';
import axios from 'axios';

const config = {
  API_BASE_URL: "https://ro-sibiu-pia-2.thoreb.com:9090/api",
  CANDY_DATA: {
    INDEX_PARAMS: {
      TIMESTAMP: 0,
      LINE: 1,
      DISTANCE: 2,
      LATITUDE: 3,
      LONGITUDE: 4,
      SPEED: 5,
      CURRENT_STOP: 6,
      VARIANT: 7,
      PUNCTUALITY: 8
    },
    UPDATE_INTERVAL: 1000
  }
};

const BusTracker = () => {
  const [buses, setBuses] = useState([]);
  const [stops, setStops] = useState([]);
  const [filteredBuses, setFilteredBuses] = useState([]);
  const [filteredStops, setFilteredStops] = useState([]);
  const [lines, setLines] = useState([]);

  const fetchBuses = async () => {
    try {
      const res = await axios.get(`${config.API_BASE_URL}/get_all_vehicles`);
      const json = res.data;
      let tempBuses = [];
      let tempLines = new Set();

      Object.keys(json).forEach((key) => {
        if (typeof json[key][config.CANDY_DATA.INDEX_PARAMS.LATITUDE] !== "undefined" &&
            typeof json[key][config.CANDY_DATA.INDEX_PARAMS.LONGITUDE] !== "undefined") {
          const busLine = json[key][config.CANDY_DATA.INDEX_PARAMS.LINE];
          tempBuses.push({
            vehicleID: key,
            timestamp: json[key][config.CANDY_DATA.INDEX_PARAMS.TIMESTAMP],
            line: busLine,
            variant: json[key][config.CANDY_DATA.INDEX_PARAMS.VARIANT],
            coords: [json[key][config.CANDY_DATA.INDEX_PARAMS.LATITUDE], json[key][config.CANDY_DATA.INDEX_PARAMS.LONGITUDE]],
            currentStop: json[key][config.CANDY_DATA.INDEX_PARAMS.CURRENT_STOP],
            distance: json[key][config.CANDY_DATA.INDEX_PARAMS.DISTANCE],
            speed: json[key][config.CANDY_DATA.INDEX_PARAMS.SPEED],
            punctuality: json[key][config.CANDY_DATA.INDEX_PARAMS.PUNCTUALITY]
          });
          tempLines.add(busLine);
        }
      });

      setBuses(tempBuses);
      setFilteredBuses(tempBuses);
      setLines(Array.from(tempLines));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchStops = async () => {
    try {
      const res = await axios.get(`${config.API_BASE_URL}/get_all_stops`);
      const json = res.data;
      let tempStops = [];

      json.forEach((v) => {
        tempStops.push({
          stopID: v.StopID,
          stopName: v.StopName,
          stopLines: v.StopLines, 
          coords: [v.Latitude, v.Longitude]
        });
      });

      setStops(tempStops);
      setFilteredStops(tempStops);
    } catch (e) {
      console.error(e);
    }
  };

  const handleStopSelect = (stop) => {
    setFilteredStops([stop]);
    setFilteredBuses(buses.filter(bus => bus.currentStop === stop.stopID));
  };

  const handleLineSelect = (line) => {
    setFilteredBuses(buses.filter(bus => bus.line === line));
    setFilteredStops(stops.filter(stop => stop.stopLines.includes(line.toString())));
  };

  useEffect(() => {
    fetchBuses();
    fetchStops();
    const interval = setInterval(fetchBuses, config.CANDY_DATA.UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ flex: 1 }}>
      <SearchComponent stops={stops} onStopSelect={handleStopSelect} lines={lines} onLineSelect={handleLineSelect} />
      <MapComponent buses={filteredBuses} stops={filteredStops} />
    </div>
  );
};

export default BusTracker;
