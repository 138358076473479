import React, { useState } from 'react';

const SearchComponent = ({ stops, onStopSelect, lines, onLineSelect }) => {
  const [query, setQuery] = useState('');

  const handleStopSelect = (stop) => {
    onStopSelect(stop);
    setQuery('');
  };

  const handleLineSelect = (line) => {
    onLineSelect(line);
    setQuery('');
  };

  const filteredStops = stops.filter(stop => stop.stopName && stop.stopName.toLowerCase().includes(query.toLowerCase()));
  const filteredLines = lines.filter(line => line && line.toString().includes(query));

  return (
    <div className="search-component">
      <input
        type="text"
        placeholder="Search for stop or line..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {query && (
        <div className="search-results">
          {filteredStops.map(stop => (
            <div key={stop.stopID} onClick={() => handleStopSelect(stop)}>
              {stop.stopName}
            </div>
          ))}
          {filteredLines.map(line => (
            <div key={line} onClick={() => handleLineSelect(line)}>
              Line {line}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
