import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const MapComponent = ({ buses, stops }) => {
  const center = [45.79477163646621, 24.137461099289677];

  // Setează iconița busului
  const busMarkerIcon = L.icon({
    iconUrl: 'https://ro-sibiu-pia-2.thoreb.com/InfoThorebWeb/bus_green_background_white.png', 
    iconSize: [25, 25],
  });

  // Setează iconița pentru stații
  const stopMarkerIcon = L.icon({
    iconUrl: 'https://ro-sibiu-pia-2.thoreb.com/InfoThorebWeb/stops_menu_selected.png', 
    iconSize: [15, 15],
  });

  // Function to get the next stop name based on the current stop ID
  const getNextStopName = (currentStopId) => {
    const stop = stops.find(stop => stop.stopID === currentStopId);
    return stop ? stop.stopName : 'N/A';
  };

  return (
    <MapContainer center={center} zoom={13} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {buses.map(bus => (
        <Marker
          key={bus.vehicleID}
          position={[bus.coords[0], bus.coords[1]]}
          icon={busMarkerIcon} 
        >
          <Popup>
            Line: {bus.line}<br />
            Speed: {bus.speed} km/h<br />
            Current Stop: {getNextStopName(bus.currentStop)}<br />
            Distanta: {bus.distance} m
          </Popup>
        </Marker>
      ))}
      {stops.map(stop => (
        <Marker
          key={stop.stopID}
          position={[stop.coords[0], stop.coords[1]]}
          icon={stopMarkerIcon} 
        >
          <Popup>
          
            Stația:<br /> 
            <p className='continer-component' style={{margin: '5px'}}>{stop.stopName}</p> <br />
            Linii comune:<br />
            {stop.stopLines.replaceAll('"', '')}
            
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
